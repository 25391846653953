@import '@kehe/phoenix-theme/kehe-theme/kehe/variables.scss';


/* Alert bar fix for show */
.orders-list-page-container {
  .alert-bar {
    margin-bottom: 16px;
  }
}

/* products list kendo grid columns for show */
.products-list-show-grid-wrapper {
  .k-grid {
    td {
      overflow: visible !important;
      padding: 0px 10px !important;
    }

    tr.k-master-row {
      height: 6.25rem !important;
    }

    .k-detail-row .k-detail-cell {
      padding: 0px !important;

      .errorMcb .k-numeric-wrap {
        background-color: #FBE9EA !important;
        border: 1px solid #DE242D !important;
      }
    }
  }

  .item-col-width {
    max-width: 26%;
    text-align: left;
    font-size: 0.75rem !important;
  }

  .price-col-width {
    width: 10.5%;
  }

  .mcb-col-width {
    width: 12%;
  }

  .qty-col-width {
    width: 12%;
    font-size: 0.75rem !important;
  }
}

/* Order details page */

.order-detail-show-page-wrapper {
  font-size: 0.75rem;

  .alert-bar {
    margin-bottom: 16px;
  }
}

.order-detail-wave-mode-table {
  .k-grid {
    .k-detail-row .k-detail-cell {
      background-color: $off-white;
    }
  }
}

/* Order details header */

.order-detail-header-container {

  .data-label-titles {
    font-size: 0.7rem;
    font-weight: 600;
  }
}

/* Order details product list */

.orders-detail-show-book-grid-wrapper {

  .item-info-col-width {
    position: relative;

    .kehe-phoenix-flag {
      transform: translate(100%) rotate(90deg);
      transform-origin: right top;

      -webkit-transform: translate(100%) rotate(90deg);
      -webkit-transform-origin: left top;

      position: absolute;
      top: 0px;
      right: -3px;
    }
  }

  .price-deal-info-col-width .k-cell-inner .k-link {
    padding: 7px !important;
  }

  .qty-col-width .k-cell-inner .k-link {
    padding: 5px !important;
    position: relative;
  }

  .free-qty-col-width .k-cell-inner .k-link {
    padding: 5px !important;
    position: relative;
  }

  .k-grid {
    .k-grid-container tr {
      height: 100px !important;
    }

    a {
      color: #297683;
      text-decoration: underline;
    }

    .k-grid-table td {
      padding: 8px 10px !important;
    }

    .k-grid-table td.item-info-col-width {
      padding-right: 16px !important;
    }

    .k-grid-table .input-in-grid {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }

    .k-numerictextbox .k-numeric-wrap .k-input {
      text-align: center;
    }

    .inputError .k-numeric-wrap {
      background-color: #FBE9EA !important;
      border: 1px solid #DE242D !important;
    }

    .k-input.k-disabled {
      &.k-input-solid,
      & .k-input-inner::placeholder {
        color: $gray-50 !important;
      }
    }
  }
}

/* Order Details Add Product Modal */
.add-single-show-product-modal {
  .text-align-right {
    text-align: right !important;
  }

  .text-align-center {
    text-align: center !important;
  }

  .k-grid kendo-numerictextbox.text-align-right .k-numeric-wrap .k-input {
    text-align: right !important;
  }

  .k-grid kendo-numerictextbox.text-align-center .k-numeric-wrap .k-input {
    text-align: center !important;
  }

  .kehe-connect-modal .kehe-modal .footer {
    margin-top: 20px !important;
  }

  .errorMcb .k-numeric-wrap {
    background-color: #FBE9EA !important;
    border: 1px solid #DE242D !important;
  }

  .k-grid {
    td.book-name-col{
      background-color: $gray-0;
      font-weight: $semi-bold;
      padding-right: 0 !important;
      padding-left: 12px !important;
    }
    td:has(kendo-numerictextbox) {
      padding-left: 2px !important;
      padding-right: 2px !important;
      height: 48px !important;
    }
  }
}


/* Order Details BULK Add Products Modal */
.order-detail-bulk-add-modal,
.orders-detail-bulk-edit-modal {
  .text-align-right {
    text-align: right !important;
  }

  .text-align-center {
    text-align: center !important;
  }

  .k-grid kendo-numerictextbox.text-align-right .k-numeric-wrap .k-input {
    text-align: right !important;
  }

  .k-grid kendo-numerictextbox.text-align-center .k-numeric-wrap .k-input {
    text-align: center !important;
  }

  .kehe-connect-modal .kehe-modal .footer {
    margin-top: 20px !important;
  }

  .errorMcb .k-numeric-wrap {
    background-color: #FBE9EA !important;
    border: 1px solid #DE242D !important;
  }

  .k-grid {
    td {
      &.book-name-col{
        background-color: $gray-0;
        font-weight: $semi-bold;
      }
      &:has(kendo-numerictextbox) {
        padding-left: 2px !important;
        padding-right: 2px !important;
        height: 48px !important;
      }
    }
  }

}

/* Order Details EP Modal */
.order-detail-ep-modal-wrapper {
  .text-align-right {
    text-align: right !important;
  }

  .text-align-center {
    text-align: center !important;
  }

  .k-grid kendo-numerictextbox.text-align-right .k-numeric-wrap .k-input {
    text-align: right !important;
  }

  .k-grid kendo-numerictextbox.text-align-center .k-numeric-wrap .k-input {
    text-align: center !important;
  }

  .kehe-connect-modal .kehe-modal .footer {
    margin-top: 20px !important;
  }

  .k-grid {
    td.book-name-col{
      background-color: #f1f1f1;
      font-weight: 600;
    }
  }

}

/* Order details buttons */
.order-detail-show-book-header {
  .btn-destructive-link .fas {
    color: #DE242D;
  }
  .btn-disabled {
    .btn {
      &.btn-link {
        border-color: transparent !important;
      }
      .align-items-center {
        div {
          color: $gray-50;
        }
      }

      .fas {
        color: $gray-50;
      }
    }
  }


  a {
    color: #297683 !important;
    text-decoration: underline !important;
    font-weight: 600 !important;
    cursor: pointer;
  }
}

.grid-order-details-show-top-menu .dollar-settings .btn-medium {
  font-size: 1rem !important;
  max-width: 42px;
  min-height: 42px;
}

/* Order detail page top menu */

.order-detail-top-menu-wrapper {

    .footer-actions .delete-button .btn-secondary {

        color: #85161B !important;
        border: 2px solid #F7CBCD !important;

        &:hover {
          color: #85161B !important;
          border: 2px solid #F7CBCD !important;
          background-color: #F7CBCD !important;
        }
    }
}

.commentContainerWrapper div.d-flex.align-items-center:has(.btn-icon) {
  position: relative;
}

.commentContainerWrapper div.d-flex.align-items-center:has(.btn-icon)::after {
  content: "";
  background-color: #DE242D;
  height: 6px;
  width: 6px;
  border-radius: 8px;
  margin-left: auto;
  position: absolute;
  bottom:10px;
  left:8px;
}

.option-dropdown-container {
  right: 0 !important;
}

.show-announcements .carousel-container {
  max-width: 1170px;
  margin-top: 16px;
  width: 100%;
}

/* Feedback button higher z index until changed in phoenix lib */
.feedback {
  z-index: 10 !important;
}

/* Product Details Modal, Mix & Match Table */
#mix-and-match-products-table {
  border: 0;

  thead {
    background-color: $gray-0;
  }

  th {
    border-bottom: 0;
    border-color: $gray-25;
    font-weight: $semi-bold;
  }

  td {
    overflow: visible !important;
    padding: 0px 10px !important;
    border-width: 0;
    border-bottom: solid 1px $gray-10;

    &.chevron-col-width {
      width: 45px;
    }

    &.img-col-width {
      width: 105px;
    }

    &.item-col-width {
      width: auto;
      text-align: left;
    }

    &.price-col-width {
      width: 176px;
      text-align: right;
    }

    &.qty-col-width {
      width: 100px;
      div {
        font-size: $font-size-small !important;
      }
    }
  }

  tbody {
    tr {
      background-color: transparent !important;

      &.k-master-row {
        height: 6.25rem !important;
      }

      &:hover {
        background-color: transparent !important;
      }
    }

    .k-detail-row .k-detail-cell {
      padding: 0px !important;
      background: $off-white;

      .k-numeric-wrap .k-input {
        text-align: right !important;
      }
    }

    .expander-row {
      .chevron-col-width {
        width: 45px;
      }

      .img-col-width {
        width: 105px;
      }

      .item-col-width {
        width: auto;
        text-align: left;
      }

      .price-col-width {
        width: 176px;
        text-align: right;
        font-size: $font-size-small !important;
      }

      .qty-col-width {
        width: 100px;
      }
    }
  }
}
