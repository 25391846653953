/* You can add global styles to this file, and also import other style files */
/* styles for the overlay of @angular/cdk used on overlays/modals */
/* for phone control */
@import 'intl-tel-input/build/css/intlTelInput.css';

@import '@kehe/phoenix-theme/kehe-theme/bootstrap/_index.scss';
@import '@kehe/phoenix-theme/kehe-theme/kehe-theme.scss';
@import './show-styles.scss';

.iti__flag {
  background-image: url("~intl-tel-input/build/img/flags.png");
}


.todo {
  background-color: $gray-0;
  border: 1px solid yellow;
  border-radius: 15px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

body {
  height: 100%;
}

app-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}


h3 {
  border-bottom: none;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

.readonly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-toggler {
  padding-left: 16px;
  padding-right: 16px;
}

/* CSS for feedback button */
.feedback-container {
  width: 100%;
  text-align: center;
}

.feedback {
  position: fixed;
  bottom: 0px;
  right: unset !important;
  left: 4.5rem !important;
  padding: 10px 20px;
  z-index: 1;
  border-radius: 2px 2px 0 0;
  background-color: #4976bf;
  border: 2px solid transparent;
  border-bottom: none;
  color: $white;
  box-shadow: thistle;
}
.feedback:hover {
  color: $white;
}
.feedback-down {
  background-color: #4976bf;
  border: 2px solid #5b84c5;
  border-bottom: none;
}

.feedback:focus {
  box-shadow: 0 0 0 0;
}

@media (max-width: 767px) {
  .feedback {
    position: relative;
    bottom: 0;
    right: 0;
    border-radius: 2px;
    padding: 10px 20px;
    z-index: 1;
    top: 7px;
  }

  .feedback-down {
    background-color: #4976bf;
    border: 2px solid #5b84c5;
  }
}
.header-user-section-container-v2 .fa-user-circle {
  font-size: 32px;
}
.header-user-section-container-v2 .fa-question-circle {
  font-size: 24px;
}

/* GRID CLASSES START */
.dg-product-grid-upc span {
  color: $teal-75;
  font-size: $font-size-small;
  font-weight: $semi-bold;
  line-height: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.dg-product-grid-selected {
  width: 42px;
}
.dg-product-grid-brand {
  width: 90px;
}
.dg-product-grid-brand span {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;
  display: block;
  overflow: hidden;
}
.dg-product-grid-item-description {
  width: 185px;
}
.dg-product-grid-item-description span {
  width: 185px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.price-change-action:hover {
  cursor: pointer;
}
.price-change-action .fas {
  color: $gray-50;
}
.price-change-action:hover .fa-trash-alt {
  color: $red-50;
}
.price-change-action:hover .fa-download {
  color: $teal-75;
}

.grid-container .link-cell a {
  word-break: break-all;
}

/* GRID CLASSES END */

iframe {
  border: none !important;
}

app-product-detail,
app-callback-page {
  flex: 1;
  display: flex;
}

/* Overrides */
.pc-details-component textarea {
  min-height: 124px;
  width: 100%;
}

.pc-details-component .close, .pc-details-component .cancel {
  display: none !important;
}
.start-price-change-modal-container .kehe-modal .content {
  max-height: 100%;
}
.price-change-step .content-container .alert-bar {
  margin-top: 0px;
}
.price-change-step .brand-options label {
  color: $text-dark;
  font-size: 12px;
  line-height: 17px;
}

#startPriceChangeModal .kehe-modal {
  overflow: initial;
}

.pc-details-component .custom-action.desktop {
  max-width: none !important;
}
.pc-details-component .custom-action.desktop a {
  cursor: pointer;
}

.orders-list-page-show-component .custom-action.desktop {
  max-width: none !important;
}

#data-exchange-delete-btn {
  color: $red-75;
  font-weight: $regular;
  border-color: $red-10
}

#data-exchange-delete-btn:hover {
  background-color: $red-0;
  border-color: $red-25;
}

.data-exchange-action {
  .fa-trash-alt:hover {
    color: $red-50;
  }

  .fa-trash-alt {
    color: $gray-50;
  }
}

.kehe-step-modal .help-content-img-container img {
  width: 100%;
  max-height: 208px;
  object-fit: contain;
  margin-bottom: 21px;
}

.kehe-step-modal .help-content-img-container img.help-content-img-3 {
  padding: 0 38px
}

.kehe-step-modal .step-content .help-content-header {
  font-family: $OpenSansFontFamily;
  font-size: $base-font-size;
  color: $text-dark;
  font-weight: $semi-bold;
  margin-bottom: 8px;
}

@media only screen and (max-height: 610px) {
  #startPriceChangeModal .kehe-modal {
    max-height: 100%;
  }
  #startPriceChangeModal .kehe-modal .content {
    max-height: 85vh;
  }
}

.product-grid-col-stacked, .score-card {
  text-transform: capitalize;
}

.product-grid-col-plain {
  text-transform: capitalize;
}

.status div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
}

.error-fg-undo-row {
  background-color: $gray-0 !important;
}

.error-instruction {
  margin-bottom: 20px;
}

.disabled-datagrid-row {
  opacity: .35;
}

/* .tooltip-text {
  visibility: hidden;
  font-size: $font-size-small;
  line-height: 1;
  color: #fff;
  background: #333;
  padding: 4px 6px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  position: absolute;
  z-index: 100;
  top: -23px;
  left: -6px;
  white-space: nowrap;
} */

/* .tooltip-icon {
  position: relative;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
} */

.disabled-icon, .disabled-icons, .disabled-icon:hover, .disabled-icons:hover {
  color: $gray-10 !important;
  cursor: default !important;
}

.right-align {
  text-align: right;
}

.dg-add-ep-item-book {
  width: 10%;
  background-color: $gray-0 !important;
  border-left: 1px solid $gray-25 !important;
  font-weight: $semi-bold !important;
}

.dg-future-order-detail-grid-book {
  width: 10%;
  background-color: $gray-0  !important;
  border-left: 1px solid $gray-25  !important;
  font-weight: $semi-bold  !important;
}

.dg-future-order-detail-grid-oi {
  width: 10%;
}

.dg-future-order-detail-grid-mcb {
  width: 10%;
  text-align: right;
}

.dg-future-order-detail-grid-qty {
  width: 10%;
}

.dg-future-order-detail-grid-free-goods {
  width: 10%;
}

.dg-future-order-detail-grid-total-qty {
  width: 10%;
}

.dg-future-order-detail-grid-price-per {
  width: 10%;
}

.dg-future-order-detail-grid-net-total {
  width: 10%;
}

.dg-future-order-detail-grid-total {
  width: 10%;
}

.dg-future-order-detail-grid-sync {
  width: 40px;
}

.dg-order-detail-grid-action-multiple {
  width: 50px;
}

.right-align {
  text-align: right;
}

.has-danger input {
  background-color: $red-0 !important;
  border: 1px solid $red-50 !important;
}

.dg-ep-deal-grid-book {
  width: 15%;
  background-color: $gray-0 !important;
  border-left: 1px solid $gray-25 !important;
  font-weight: 600 !important;
}

.dg-ep-deal-grid-supplier {
  width: 30%;
}

.dg-ep-deal-grid-item {
  width: 20%;
}

.dg-ep-deal-grid-cost {
  width: 13%;
}

.dg-ep-deal-total {
  width: 13%;
}

.dg-add-future-order-line-item-book {
  width: 10%;
  background-color: $gray-0 !important;
  border-left: 1px solid $gray-25 !important;
  font-weight: 600 !important;
}

.dg-add-future-order-line-item-mcb {
  width: 10%;
}

.dg-add-future-order-line-item-qty {
  width: 10%;
}

.dg-add-future-order-line-item-free-goods {
  width: 10%;
}

.dg-add-future-order-line-item-total-qty {
  width: 10%;
}

.dg-add-ep-item-cost {
  width: 10%;
}

.dg-add-ep-item-total {
  width: 10%;
}

.quantity-text-position {
  position: relative;
  top: 5px;
}

// begin detail global styles
.dropdown-tab-container {
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.kehe-data-section {
  div.row {
    margin-bottom: $spacer;
  }
  div.row:last-child {
    margin-bottom: 0;
  }
}

.product-detail-tab-content .k-grid .k-grid-aria-root {
  overflow:  hidden !important;
}
// end detail global styles


/* Delete Button and Delete Button for Modal */
.btn-delete {
  border: 2px solid $red-10 !important;
  color: $red-75 !important;

  &:hover {
    background-color: $red-0 !important;
    border: 2px solid $red-10 !important;
    color: $red-75 !important;
  }

  &:focus {
    box-shadow: 0 0 2px $red-25 !important;
    border: 2px solid $red-25 !important;
    color: $red-100 !important;
    background-color: $red-10 !important;
  }

  &:active {
    box-shadow: 0 0 2px $red-25 !important;
    border: 2px solid $red-25 !important;
    color: $red-100 !important;
    background-color: $red-10 !important;
  }

  &.disabled,
  &:disabled {
    background-color: $gray-25 !important;
    border: 2px solid $gray-25 !important;
    color: $white !important;
  }
}

.fltLt {
  float: left;
}

.fltRt {
  float: right;
}

.loadmore {
  min-width: 130px;
}


/* Overrides for item-list-add-to-cart */
.quantityTextBox {
  text-align: center;
}

.quantityTextBoxEdit {
  text-align: center;
  height: 30px !important;
}

/* global styles for backdrops at modal workflow home page */
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  border: 1px solid $gray-50;
  background-color: rgba(0, 0, 0, 0.3);
}

.status div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
}

.new-product-status div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
}

@media only screen and (max-width: 982px) {
  .new-product-status div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
  }
}


.gap20bottom {
  margin-bottom: 20px;
}

/* Kendo Toast styles */
kendo-notification-container {
  z-index: 1000 !important;
}

kendo-notification-container>div>kendo-notification>div {
  max-width: 320px !important;
}

kendo-notification-container>div>kendo-notification>div>div>div>app-add-line-item-toast>div>div>img {
  max-height: 85px;
  width: auto;
}

.toast-link {
  text-decoration: underline !important;
}

/* Toast styles */
#toast-container>div.toast-custom {
  padding: 5px;
  color: $white;
}

#toast-container>div.toast-custom .toast-close-button {
  color: $white !important;
}

.toast-top-right {
  top: 120px;
  left: 10px;
  right: inherit;
}

#toast-container>div {
  -webkit-box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.5);
  padding: 10px 10px 10px 10px;
  width: calc(100vw - 20px);
}

#toast-container .product-image-container {
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 5px;
}

#toast-container .product-image {
  max-width: 50px;
  max-height: 50px;
}

#toast-container .text-container {
  color: $white;
}

#toast-container .order-info {
  font-size: $base-font-size;
  color: $white;
}

#toast-container .order-info-strong {
  font-weight: $semi-bold;
  font-size: $base-font-size;
  color: $white;
}

#toast-container .order-info-view-order {
  margin: 10px 0px 5px 0px;
}


.toast {
  background-color: $black;
  border: 1px solid $gray-50;
}

.toast-close-button {
  right: 0em;
  top: -0.2em;
  color: $white;
}

button.toast-close-button {
  color: $white;
}

@media (max-width: 480px) and (min-width: 241px) {
  #toast-container .toast-close-button {
    right: 0em;
    top: -0.2em;
    color: $white;
  }

  button.toast-close-button {
    color: $white;
  }
}

@media screen and (min-width: 768px) {
  #toast-container>div.toast-custom {
    padding: 5px;
    color: $text-dark;
  }

  #toast-container>div.toast-custom .toast-close-button {
    color: $teal-50 !important;
  }

  #toast-container>div {
    -webkit-box-shadow: 3px 3px 7px 0px $gray-25;
    box-shadow: 3px 3px 7px 0px $gray-10;
    padding: 5px 5px 5px 5px;
    width: 300px;
  }

  #toast-container> :hover {
    -webkit-box-shadow: 3px 3px 7px 0px $gray-50;
    box-shadow: 3px 3px 7px 0px $gray-50;
  }

  .toast-top-right {
    right: 16px;
    left: inherit;
  }

  #toast-container .product-image {
    max-width: 50px;
    max-height: 50px;
  }

  #toast-container .text-container {
    color: $text-dark;
  }

  #toast-container .order-info {
    font-size: $font-size-small;
    color: $text-dark;
  }

  #toast-container .order-info-strong {
    font-weight: $semi-bold;
    font-size: $font-size-small;
    color: $text-dark;
  }

  #toast-container .order-info-view-order {
    margin: 10px 0px 5px 0px;
  }

  #toast-container .order-info-view-order-button {
    border: 1px solid $teal-50;
    color: $teal-50;
    padding: 4px;
    font-size: $font-size-footnote;
  }

  .toast {
    background-color: $white;
    border: 1px solid $gray-50;
  }

  .toast-close-button {
    right: 0em;
    top: -0.25em;
  }

  .toast-close-button:hover {
    color: $teal-50;
  }

  button.toast-close-button {
    color: $teal-50;
  }
}

/* Data grid on order detail page */
.d-grid-body {
  min-height: 30px !important;
}

.dg-order-detail-item {
  min-width: 25%;
}

.dg-order-detail-upc {
  text-align: center;
  width: 15%;
}

.dg-order-detail-casepack {
  text-align: center;
  width: 10%;
}

.dg-order-detail-quantity {
  text-align: right;
  width: 15%;
}

.dg-order-detail-unitprice {
  text-align: right;
  width: 15%;
}

.dg-order-detail-totalprice {
  text-align: right;
  width: 15%;
}

.dg-order-detail-delete {
  width: 5%;
  text-align: center;
  cursor: pointer;
}

.dg-order-detail-delete * {
  pointer-events: none;
}

/* new grid extra classes */
.error-message-hover {
  color: $red-50;
  margin-left: 15px;
}

.success-message-hover {
  color: rgb(22, 207, 15);
  margin-left: 15px;
}

.document-searchbar {
  text-align: right !important;
  max-width: 1px;
}

/* Document Grid */

.document-grid-item-selected {
  width: 2%;
  text-align: center !important;
}

.document-grid-item-selected>* {
  pointer-events: none;
}

.document-grid-item-file-type-icon {
  width: 3%;
  text-align: center !important;
}

.document-grid-item-file-type-icon>* {
  pointer-events: none;
}

.document-grid-item-title {
  width: 20%;
}

.document-grid-item-title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document-grid-item-publication-type {
  width: 10%;
}

.document-grid-item-file-ext {
  width: 5%;
  text-align: left !important;
}

.document-grid-item-modified {
  width: 5%;
}

.document-grid-item-file-size {
  width: 5%;
  text-align: right !important;
}

@media screen and (min-width: 768px) {
  .document-grid-item-selected {
    width: 2%;
  }

  .document-grid-item-file-type-icon {
    width: 3%;
  }

  .document-grid-item-title {
    width: 20%;
  }

  .document-grid-item-publication-type {
    width: 10%;
  }

  .document-grid-item-file-ext {
    width: 5%;
  }

  .document-grid-item-modified {
    width: 5%;
  }

  .document-grid-item-file-size {
    width: 5%;
    text-align: right !important;
  }
}

.spinner:before {
  z-index: 9999;
  display: none;
}

/* Styles for invoice list data grid */
.dg-invoice-list-number {
  width: 20%;
  cursor: pointer;
}

.dg-invoice-list-number-link {
  pointer-events: none;
}

.dg-invoice-list-date {
  width: 20%;
  text-align: center;
}

.dg-invoice-list-po {
  width: 20%;
}

.dg-invoice-list-totalvalue {
  text-align: right;
  width: 20%;
}

.dg-invoice-list-lines {
  text-align: right;
  width: 20%;
}

.dg-invoice-download {
  width: 5%;
  cursor: pointer;
  text-align: center;
}

.dg-invoice-download * {
  pointer-events: none;
}

/* Override for button component */
.short-btn {
  padding: 1px !important;
}

/* Override for navbar height */
@media screen and (min-width: 992px) {
  .navbar {
    height: 40px;
  }
}

#btn-browse-brands {
  width: 175px;
  height: 50px;
  font-family: $OpenSansFontFamily;
  color: $text-dark;
  font-size: 0.875rem;
}

#brands div.kehe-modal {
  width: 75%;
}

#brands div.kehe-modal div.content {
  height: 500px;
  padding-top: 0;
}

.open-modal-link {
  color: $teal-50;
  cursor: pointer;
}

.open-modal-link:hover {
  color: $teal-50;
}

.hidden {
  display: none;
}

#export-price-dc div.kehe-modal div.content {
  width: 500px;
  height: 171px;
}

#export-price-customer div.kehe-modal div.content {
  width: 500px;
  height: 499px;
}

.form-container {
  app-dropdown {
    label {
      font-size: $font-size-small;
    }

    .selectTextContainerDesktop {
      height: 40px;
    }
  }
}

.main-container>app-document-list-page,
.main-container>app-supplier-detail-page,
.main-container>app-broker-detail-page,
.main-container>app-customer-page,
.main-container>app-customer-detail-page,
.main-container>app-show-detail-page {
  flex: 1;
  display: block;
}

.product-grid-col-stacked {
  text-transform: capitalize;
}

.product-grid-col-plain {
  text-transform: capitalize;
}

.product-grid-col-override {
  color: $text-dark;
  text-decoration: underline $white;
  text-transform: capitalize;
  font-weight: $regular;
  cursor: none;
}

.product-grid-item-checked {
  width: 2%;
  text-align: center !important;
}

.product-grid-item-checked>* {
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .product-grid-item-checked {
    width: 2%;
  }
}

/* override for chips placement for supplier-list chips */
app-supplier-page .chips-panel.opened-height,
app-customer-page .chips-panel.opened-height {
  align-items: flex-end !important;
}

app-customer-page app-slide-panel .date-input-wrap,
app-customer-page app-slide-panel .dateInput {
  width: 100% !important;
}

.dc-supplier-grid-po-trans {
  word-wrap: break-word;
}

.broker-list-column-ebn {
  width: 18%;
  min-width: 100px;
}

app-slide-panel,
kehe-phoenix-slide-panel {
  .slide-panel-content {
    padding-top: 0 !important;
    margin-bottom: 30px;

    .header {
      padding: 20px 20px 8px 20px;
      margin-bottom: 15px;
      position: absolute;
      top: 0;
      left: 0;
      right: 40px;
    }

    .actions {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 10px 20px 20px 20px;
      background-color: $white;
      z-index: 1001;

      .cancel {
        margin-left: 8px;
      }
    }
  }
}

.edit-mode {
  .has-danger {
    input {

      &.error,
      &.error:focus {
        border-color: $red-50 !important;
        outline-offset: 0px !important;
        outline: none !important;
        box-shadow: none;
      }
    }

    .form-control-feedback p {
      text-transform: none;
    }
  }
}

.show-details-component {
  .suppliers-tab {
    .fa-trash-alt:hover {
      color: $red-50 !important;
    }

    .show-supplier-list-checkbox,
    .show-supplier-list-delete {
      width: 42px;

      .g-grid-icons-button {
        justify-content: center;
      }
    }

    .show-supplier-list-date {
      width: 230px;
    }

    .show-supplier-list-count {
      width: 105px;
      text-align: center !important;
    }
  }

  .retailers-tab {
    .fa-trash-alt:hover {
      color: $red-50 !important;
    }

    .show-retailer-list-checkbox,
    .show-retailer-list-delete {
      width: 42px;

      .g-grid-icons-button {
        justify-content: center;
      }
    }

    .show-retailer-list-date {
      width: 230px;
    }

    .show-retailer-list-count {
      width: 105px;
      text-align: center !important;
    }
  }
}

.form-group app-tooltip {
  color: $blue-50;
  margin: 0 8px;
}

.product-summary .summary-info.form-group .form-group {
  margin-bottom: 0;
}

[isDisabled] .selectTextContainerDesktop {
  background: $gray-10 !important;
  border: 1px solid $gray-25 !important;

  .chevron {
    display: none;
  }
}

.score-card .form-group {
  margin-bottom: 0px;
}

.upc .input-group {
  width: 60%;
}

.ean .input-group {
  width: 60%;
}

.price-group .input-group {
  width: 50%;
}

.prod-dimen-container kendo-numerictextbox input {
  text-align: center;
}

.uneditable {
  .form-group {
    margin-bottom: 0rem;

    input {
      color: $text-dark;
    }
  }
}

.form-checkbox-margin {
  margin-bottom: 8px;
  margin-left: 2px;
}

.name .form-group,
.case-gtin .form-group,
.ean .form-group,
.shelf-life-in-days .form-group,
.supplier-tie-hi .form-group,
.shelf-label-name .form-group,
.uom-size .form-group,
.brand .form-group,
.category-manager .form-group,
.c-of-origin .form-group,
.product-class .form-group,
.level1-code .form-group,
.level2-code .form-group,
.level2-code .error-message-text,
.level3-code .form-group,
.level4-code .form-group,
.level4-code .error-message-text,
.level5-code .form-group,
.prod-dimen-container .form-group,
.supplier-tie-hi .form-group,
.level5-code .error-message-text {
  margin-bottom: 0;
}

.is-inner-pk,
.cool-c,
.level3-code,
.level5-code {
  margin-top: 10px;
}

.prod-dimen-container .textboxComponent {
  text-align: center;
}

.add-supplier-modal .alert-bar {
  margin: 0px !important;
}

#ddMinimumPromotion .dropdownContainer,
#ddPromoPeriod .dropdownContainer,
#ddPackaging .dropdownContainer,
#ddDivision .dropdownContainer {
  z-index: 1000;
}

.add-show-customers-modal-container {
  .kehe-modal {
    overflow: visible !important;

    .content {
      overflow: inherit !important;
    }
  }
}


.modal-btn-spacing {
  margin: 0 0 0 8px;
}

.textbox-icon-addon {
  z-index: 15 !important;
}

.tooltip {
  text-align: left !important;
}

.grid-edit-icon-cell {
  width: 41px;
  text-align: center !important;
  padding: 0 0 0 15px !important;
}

.po-trans-button-postions {
  max-width: 49.2px;
  margin: 18px 0 0 0;
}

// begin detail global styles
.dropdown-tab-container {
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.kehe-data-section {
  div.row {
    margin-bottom: $spacer;
  }

  div.row:last-child {
    margin-bottom: 0;
  }
}

.info-tooltip {
  color: $blue-50;
  margin-left: 0.25rem;
}

// end detail global styles

app-product-page {
  overflow: hidden;
}


.attributes-tab {
  label {
    position: relative !important;
  }
  .attributes-section .k-label {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
    font-weight: 600 !important;
    font-size: 12px;
  }
}

.search-shipper-product .kehe-chips-container {
  display: none;
}

#accountinvoicesmodal .body {
  overflow-y: unset;
}

.has-danger input {
  background-color: $gray-0 !important;
  border: 1px solid $red-50 !important;
}

.kehe-pack-text .k-input {
  text-align: center !important;
}

a.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}


// Form
.form-container {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $gray-25;
  background-color: $white;
  padding: 24px;
  margin-bottom: 24px;
}

.kehe-connect-modal .kehe-modal .body .alert-bar {
  margin: 0 0 20px 0;
}

kehe-phoenix-feedback-modal .kehe-connect-modal .kehe-modal .body .alert-bar {
  margin: 0 0 0 0;
}

.comment-container-wrapper div.d-flex.align-items-center:has(.btn-icon) {
  position: relative;
}

.comment-container-wrapper div.d-flex.align-items-center:has(.btn-icon)::after {
  content: "";
  background-color: $red-50;
  height: 6px;
  width: 6px;
  border-radius: 8px;
  margin-left: auto;
  position: absolute;
  bottom:10px;
  left:8px;
}
.kehe-tooltip-show{
  z-index: 10000 !important;
}

app-upload-documents-modal, app-delete-product-modal {
  .fields-wrap {
    overflow-x: hidden;
  }
}

app-delete-document-modal, app-add-purchasing-option-modal {
  .kehe-connect-modal .kehe-modal .footer {
    margin-top: 0 !important;
  }
}


app-add-product-modal {
  .fields-wrap {
    width: calc(100% - 15px);
  }
  .char-length {
    float: right;
    background-color: transparent;
    font-size: 10px;
    color: #666666 !important;
  }
  .form-group input:disabled {
    border: none !important;
  }
}
#document-delete-btn {
  color: $red-75;
  font-weight: $regular;
  border-color: $red-10
}
#document-delete-btn:hover {
  background-color: $red-0;
  border-color: $red-25;
}


app-dc-details-view {
  .kehe-connect-modal .kehe-modal .body .alert-bar {
    margin: 14px 0 20px 0;
  }
}

.kehe-grid {
  .align-right {
    text-align: end !important;
  }

  .align-center {
    text-align: center !important;
  }

  .align-left {
    text-align: start !important;
  }
}

app-home-page {
  .home-page-component .alert-bar {
    margin-top: 0px !important;
  }
}

app-supplier-detail-page .supplier-detail-component .supplier-detail-content .alert-container {
  margin: 16px 0px;

  & div.alert-bar {
    margin: 8px 0;
  }
}

.data-exchange-cart-toast {
  .icon-container {
    background-color: $gray-10;
  }
  .icon {
    font-size: 2rem;
    color: $gray-75;
  }
  .text {
    font-weight: $regular;
  }
}

.v1-navigate-alert .custom-action.desktop {
  max-width: 100% !important;
}

.k-grid {
  .k-detail-row .k-detail-cell {
    padding: 0px !important;
  }
}

.kehe-nested-grid .k-grid-content {
  z-index: 0;
}

.k-grid .k-hierarchy-cell {
  width: 0;
  display: none;
}
.k-grid .k-header.k-hierarchy-cell {
  border: none;
  display: none;
}
.k-grid .k-hierarchy-cell .k-icon {
  display: none;
}


app-view-price-lists .kehe-connect-modal .kehe-modal .body {
  overflow-y: clip !important;
 }


.roadmap-national-deals-grid-wrapper,
.bulk-edit-oi-mcb-table-grid-wrapper {
  .k-grid {
    .k-numerictextbox.text-center .k-input-inner {
      text-align: center !important;
    }

    .k-numerictextbox.text-end .k-input-inner {
      text-align: right !important;
    }

    .errorInput {
      background-color: $red-0 !important;
      border: 1px solid $red-50 !important;
    }

    .errorInput .k-numeric-wrap {
      background-color: $red-0 !important;
      border: 1px solid $red-50 !important;
    }
  }

  .k-input.k-disabled {
    &.k-input-solid .k-input-inner,
    & .k-input-inner::placeholder {
      color: $gray-50 !important;
    }
  }
}

.promotional-roadmap-nd-add-products-product-card {
  .k-checkbox-label {
    label.k-label {
      font-weight: $bold;
    }
  }
}

.promotional-roadmap-advertising-add-products-product-card {
  .k-checkbox-label {
    label.k-label {
      font-weight: $bold;
    }
  }
}

.custom-promotions-list-page {
  .kehe-grid {
    width: auto;
  }

  .grid-longtext {
    overflow-wrap: break-word;
  }
}

.promotion-upload-modal-wrapper {
  .k-label {
      display: inline;
  }

  kendo-label:not(.k-checkbox-label) label.k-label {
    font-weight: $bold;
  }
}

.promotional-roadmap-comments-section-wrapper {
  .limited-height.k-textarea {
    max-height: 80px !important;
    resize: none !important;
    min-height: 80px !important;
    textarea.k-input-inner {
      max-height: 80px !important;
      resize: none !important;
    }
  }
}

.download-supplier-template-form {
  .k-label {
    font-weight: $bold;
  }
}
