@import '@kehe/phoenix-theme/kehe-theme/kehe/variables.scss';

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: 999;
  font-size: 0.75;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: 999;
  font-size: 0.75;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: 999;
  font-size: 0.75;
}
